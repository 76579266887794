import axiosInstance from "./axios-instance";

const addDraperyOrderFabric = async (payload: any) => {
    try {
        const response = await axiosInstance.post<any>(
            "/draperyOrderFabric/add",
            payload
        );

        return response.data;
    } catch (error) {
        console.error("Error add drapery order fabric:", error);
        throw error;
    }
};

const editDraperyOrderFabric = async (payload: any) => {
    try {
        const response = await axiosInstance.put<any>(
            "/draperyOrderFabric/edit",
            payload
        );

        return response.data;
    } catch (error) {
        console.error("Error edit drapery order fabric:", error);
        throw error;
    }
};

export { addDraperyOrderFabric, editDraperyOrderFabric };
