import { ItemClasses, ModalName } from "./types";
import React, {
    FC,
    Fragment,
    MouseEvent,
    ReactElement,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { SearchSelectProps } from "../../components/UI/search_select/search_select";
import { DispatchProp, connect } from "react-redux";
import {
    getAll as getAllItems,
    updateInactiveBulkRecord,
} from "../../api/item";
import { RouteComponentProps } from "react-router-dom";
import {
    RootState,
    DraperyModule,
    SuppliersModule,
} from "../../redux";
import { ActionButton } from "./action-button/action-button";
import AddButton from "../UI/buttons/add-button";
import { AvatarImage } from "../UI/avatar";
import { Category } from "../../interfaces/category";
import { Checkbox } from "@ramble/ramble-ui";
import { ConfirmationModal } from "../../modals/confirmation_modal";
import { AddToClientModal } from "../../modals/add-to-client-modal";
import { ContentSection } from "../content/content_section";
import DraperyItemModal from "./drapery-item-modal/main";
import { FaArrowDown } from "react-icons/fa";
import FabricsOrLiningsItemModal from "./fabrics-or-linings-item-modal/main";
import { FormFields } from "../../redux/modules/my-shop/form-fields-reducer";
import { PageContent } from "../content/page_content";
import { PageHeader } from "./page-header/page-header";
import StandardItemModal from "./standard-item-modal/main";
import SubtitleSection from "./sections/subtitle-section";
import { Supplier } from "../../interfaces/supplier";
import TitleSection from "./sections/title-section";
import { getAll as getAllCategories } from "../../api/category";
import {
    addSupplier,
    getSuppliers as getAllSuppliers,
} from "../../api/supplier";
import SupplierModal from "../../containers/supplier/supplier_modal";
import { FormValidationError } from "@ramble/ramble-ui";
import { SortTableRowInfo } from "../../components/UI/table/sort_table";
import { defaultDraperyOrderDesign } from "../calculator/design-tab";
import { FadeLoader } from "react-spinners";
import { addDraperyOrderItem } from "../../api/drapery-order";
import { searchClients } from "../../api/client";

export interface FilterValues {
    supplier: Supplier | undefined;
    category: Category | undefined;
    activeType: "active" | "inactive" | "all" | "retail" | "trade";
    minCost: number | undefined;
    maxCost: number | undefined;
}

export interface MyShopItem extends FormFields {
    supplier?: string;
    itemClassId?: number;
    activeItemClassId?: number;
}

interface MyShopSubcategory extends Category {
    items?: MyShopItem[];
}

export interface MyShopCategory extends Category {
    subcategories?: MyShopSubcategory[];
    items?: MyShopItem[];
}

interface ClientOwnProps extends RouteComponentProps {}

interface ClientsProps extends ClientOwnProps {
    accountId: number;
    history: any;
}

interface MyShopProps {
    history: any;
    accountId: number;
    isOpenModal: boolean;
    handleCloseModal: () => void;
}

const defaultDraperyOrderItem = (
    id?: number,
    clientId?: number,
    clientName?: string
): DraperyModule.DraperyOrderItem & { clientName: string } => ({
    id: id || 0,
    window: "",
    related: "",
    quantity: 1,
    hasRelatedWorkOrders: false,
    relatedWorkOrderIds: [],
    workOrderId: undefined,
    isReturnLeftoverMaterials: false,
    clientId: clientId || 0,
    clientName: clientName || "",
    status: "Planning",
    editable: true,
    userConfirmation: {},
    archived: false,
    isDraft: false,
});

interface ClientInfo {
    id: number;
    itemIds: number[];
}

export const createItemRows = (
    cat: MyShopCategory[],
    supp: any,
    itemList: MyShopItem[]
) => {
    // Sort categories by name first
    cat.sort((a, b) => (a.name < b.name ? 1 : -1));
    // Then sort all categories before subcategories so we can find parent categories of subs
    cat.sort((a, b) => (a.parentCategoryId && !b.parentCategoryId ? 1 : -1));

    // Place subcategories in each category
    const categoryHierarchy = cat.reduce((acc: any, category: Category) => {
        if (category.parentCategoryId === null) {
            return [...acc, { ...category, subcategories: [], items: [] }];
        }

        const parentCategoryIndex = acc.findIndex(
            (tempCat: any) => tempCat.id === category.parentCategoryId
        );

        if (parentCategoryIndex !== -1) {
            acc[parentCategoryIndex].subcategories.push({
                ...category,
                items: [],
            });
        }

        return acc;
    }, []);

    let tempRows = itemList;

    // const categoryHierarchy = arrayToTree(cat, null);
    // Place supplier names in each item
    if (supp) {
        tempRows.forEach((item: MyShopItem) => {
            const foundSupplier = supp.find(
                (element: any) => element.id === item.supplierId
            );
            item.supplier = foundSupplier ? foundSupplier.displayName : "";
        });
    }

    // place each item in categories and subcategories
    tempRows.forEach((item) => {
        categoryHierarchy.forEach((category: any) => {
            if (category.id === item.categoryId) {
                category.items.push(item);
            } else {
                category.subcategories.forEach(
                    (subcategory: MyShopSubcategory) => {
                        if (
                            subcategory.id === item.categoryId &&
                            subcategory.items
                        ) {
                            subcategory.items.push(item);
                        }
                    }
                );
            }
        });
    });
    return categoryHierarchy;
};

// Props passees in the history component
const MyShop: FC<MyShopProps & DispatchProp> = (props) => {
    let draperyItem: any = localStorage.getItem("draperyItem");
    if (draperyItem) {
        draperyItem = JSON.parse(draperyItem);
    } else {
        draperyItem = {};
    }

    const searchParams = new URLSearchParams(window.location.search);
    const rippleFoldPopUp = searchParams.get("ripplefold_pop_up");

    const [tabIndex, setTabIndex] = useState<number>(0);
    const [highLightInput, setHighLightInput] = useState<string[]>([]);

    let showDraperyModal = draperyItem.showDraperyModal || false;
    const [activeModal, setActiveModal] = useState<string | null>(null);
    const [activeItemClassId, setActiveItemClassId] = useState<number | null>(
        null
    );
    const [clientProjectModal, setClientProjectModal] =
        useState<boolean>(false);

    // Retrieved objects from API
    const [categories, setCategories] = useState<Category[]>([]);
    const [suppliers, setSuppliers] = useState<Supplier[]>([]);
    const [allItems, setAllItems] = useState<MyShopItem[]>([]);

    // Object we use to display in grid
    const [itemRows, setItemRows] = useState<MyShopCategory[]>([]);
    const [sortType, setSortType] = useState<string>("name");

    const [checkedItems, setCheckedItems] = useState<MyShopItem[]>([]);
    const [inactiveModal, setInactiveModal] = useState<boolean>(false);
    const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
    const [activeOnly, setActiveOnly] = useState<boolean>(true);
    const [clientInfo, setClientInfo] = useState<ClientInfo>({
        id: 0,
        itemIds: [],
    });
    const [search, setSearch] = useState<string>("");
    const draperyOrderItem = defaultDraperyOrderItem();
    const draperyOrderDesign = defaultDraperyOrderDesign();
    const [isAddSupplierModalOpen, setIsAddSupplierModalOpen] =
        useState<boolean>(false);
    const [isChecked, setIsChecked] = useState(false);
    // const [result, setResult] = useState<Category>([]);
    const [stickyBackround, setStickyBackround] = useState("#fff");
    const [priceRadio, setPriceRadio] = useState("retail");
    const inputRef = useRef<HTMLDivElement>(null);
    const closeModal = () => {
        setActiveModal(null);
        setActiveItemClassId(null);
        setSelectedItemId(null);
        setHighLightInput([]);
        props.handleCloseModal();
    };
    const [supplierName, setSupplierName] = useState("");
    const [supplierId, setSupplierId] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    // Check item for editing
    const checkItem = (cItem: MyShopItem) => {
        let tempArr;
        if (checkedItems.find((i) => i.id === cItem.id)) {
            tempArr = checkedItems.filter((i) => i.id !== cItem.id);
        } else {
            tempArr = [...checkedItems, cItem];
        }

        setCheckedItems(tempArr);
    };

    // Sort values based on selected column
    const handleSort = (sortT: string) => {
        let isReverse = false;
        if (sortT === sortType) {
            isReverse = true;
            setSortType(`${sortT}_desc`);
        } else {
            setSortType(sortT);
        }
        const filterItems = filterRows(search);
        const arr = [
            ...filterItems.searchFilterName,
            ...filterItems.searchFilterItem,
            ...filterItems.searchFilterSupplier,
            ...filterItems.searchSalesDescription,
        ];
        const tempItems = sortItems(sortT, arr, isReverse);

        // Sort items by property
        const itemSortedCategories = createItemRows(
            categories.filter((cat) =>
                search
                    ? arr.some((item) => item.categoryId === cat.id)
                    : categories
            ),
            suppliers,
            search
                ? tempItems.filter(
                      (value, index, arr) =>
                          index === arr.findIndex((t) => t.id === value.id)
                  )
                : allItems
        );
        // Then sort the categories they're in
        const temp = sortCategories(sortT, itemSortedCategories, isReverse);
        setItemRows(temp);
    };

    const handleInactiveOnly = () => {
        if (search) {
            onSearchItemClick(undefined, search, !activeOnly);
        } else {
            fetchAll(!activeOnly);
        }
        setActiveOnly(!activeOnly);
    };

    // Get all values (suppliers, categories, items)
    const getSuppliers = async () => {
        const res: any = await getAllSuppliers();
        setSuppliers(res);

        if (!res.error) {
            return res;
        }
    };
    const getCategories = async (activeOnly: boolean) => {
        const catRes = await getAllCategories({
            isActiveOnly: activeOnly || undefined,
        });
        setCategories(catRes);
        return catRes;
    };
    const fetchAll = useCallback(async (activeOnly = true) => {
        let supp: any;
        let cat: MyShopCategory[];
        let itemList: MyShopItem[];
        try {
            setIsLoading(true);
            supp = await getSuppliers();
            cat = await getCategories(activeOnly);
            itemList = await getAllItems({ isActiveOnly: activeOnly });
        } catch (error) {
            console.error("Error fetching items:", error);
            setIsLoading(false);
            return;
        }

        // Set all API values so we can order/filter them later
        setAllItems(itemList);

        const finalRows = createItemRows(cat, supp, itemList);
        setItemRows(finalRows); // set items state
        setIsLoading(false);
    }, []);
    const fetchAllWrapper = () => {
        fetchAll()
            .then()
            .catch(() => console.log("error in fetchlistwrapper"));
    };

    const reloadGrid = useCallback(() => {
        getAllItems({ isActiveOnly: activeOnly })
            .then((result) => {
                setAllItems(result);
                const finalRows = createItemRows(categories, suppliers, result);
                setItemRows(finalRows);
            })
            .catch((err) => console.error("Error fetching items:", err));
    }, [categories, suppliers]);

    //get tree structure from flat array
    const arrayToTree = (arr: Category[], parentId: number | null): any =>
        arr
            .filter((item) => item.parentCategoryId === parentId)
            .map((child) => ({
                ...child,
                items: [],
                subcategories: arrayToTree(arr, child.id),
            }));

    // Organize categories and items
    // const createItemRows = (
    //     cat: MyShopCategory[],
    //     supp: any,
    //     itemList: MyShopItem[]
    // ) => {
    //     // Sort categories by name first
    //     cat.sort((a, b) => (a.name < b.name ? 1 : -1));
    //     // Then sort all categories before subcategories so we can find parent categories of subs
    //     cat.sort((a, b) =>
    //         a.parentCategoryId && !b.parentCategoryId ? 1 : -1
    //     );

    //     // Place subcategories in each category
    //     const categoryHierarchy = cat.reduce((acc: any, category: Category) => {
    //         if (category.parentCategoryId === null) {
    //             return [...acc, { ...category, subcategories: [], items: [] }];
    //         }

    //         const parentCategoryIndex = acc.findIndex(
    //             (tempCat: any) => tempCat.id === category.parentCategoryId
    //         );

    //         if (parentCategoryIndex !== -1) {
    //             acc[parentCategoryIndex].subcategories.push({
    //                 ...category,
    //                 items: [],
    //             });
    //         }

    //         return acc;
    //     }, []);

    //     let tempRows = itemList;

    //     // const categoryHierarchy = arrayToTree(cat, null);
    //     // Place supplier names in each item
    //     if (supp) {
    //         tempRows.forEach((item: MyShopItem) => {
    //             const foundSupplier = supp.find(
    //                 (element: any) => element.id === item.supplierId
    //             );
    //             item.supplier = foundSupplier ? foundSupplier.displayName : "";
    //         });
    //     }

    //     // place each item in categories and subcategories
    //     tempRows.forEach((item) => {
    //         categoryHierarchy.forEach((category: any) => {
    //             if (category.id === item.categoryId) {
    //                 category.items.push(item);
    //             } else {
    //                 category.subcategories.forEach(
    //                     (subcategory: MyShopSubcategory) => {
    //                         if (
    //                             subcategory.id === item.categoryId &&
    //                             subcategory.items
    //                         ) {
    //                             subcategory.items.push(item);
    //                         }
    //                     }
    //                 );
    //             }
    //         });
    //     });
    //     return categoryHierarchy;
    // };

    const handleRowClick = ({
        event,
        rowId,
        iClassId,
    }: {
        event?: MouseEvent<HTMLDivElement>;
        rowId: number;
        iClassId?: ItemClasses;
    }) => {
        let itemClassId;
        if (iClassId) {
            itemClassId = iClassId;
        } else if (event) {
            itemClassId = +event.currentTarget.dataset.classId!;
        } else {
            return;
        }

        if (
            [
                ItemClasses.Standard,
                ItemClasses.FabricsOrLining,
                ItemClasses.Drapery,
            ].includes(itemClassId)
        ) {
            setActiveItemClassId(itemClassId);
        } else {
            setActiveItemClassId(null);
        }

        switch (itemClassId) {
            case ItemClasses.Standard:
            case ItemClasses.FabricsOrLining: {
                setActiveModal(ModalName.StandardItem);
                break;
            }
            case ItemClasses.Drapery: {
                setActiveModal(ModalName.DraperyItem);
                break;
            }
            default: {
                setActiveModal("");
                break;
            }
        }
        setSelectedItemId(rowId);
    };
    useEffect(() => {
        const handleScroll = () => {
            if (inputRef.current) {
                if (window.scrollY > 54) {
                    setStickyBackround("#fff !important");
                } else {
                    setStickyBackround("#000 !important");
                }
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    });

    useEffect(() => {
        if (showDraperyModal) {
            getAllItems({ isActiveOnly: true }).then((res) => {
                const draperyItem = res.filter(
                    (item) => item.name === "Drapery"
                );
                setSelectedItemId(draperyItem[0].id || null);
            });
        }
    }, [showDraperyModal]);

    useEffect(() => {
        window.addEventListener("beforeunload", () => {
            draperyItem.showDraperyModal = false;
            localStorage.setItem("draperyItem", JSON.stringify(draperyItem));
        });
        window.addEventListener("popstate", () => {
            draperyItem.from = "/myshop";
            localStorage.setItem("draperyItem", JSON.stringify(draperyItem));
        });

        return () => {
            window.removeEventListener("beforeunload", () => {});
            window.removeEventListener("popstate", () => {});
        };
    }, []);

    const makeItemsInactive = async () => {
        let tempItems = checkedItems.filter(
            (el) => el.itemClassId !== ItemClasses.Drapery
        );
        const payload = tempItems.map((el) => el.id);

        if (checkedItems.length > 0) {
            await updateInactiveBulkRecord(payload);
        }
        fetchAllWrapper();
        setActiveOnly(true);
        setCheckedItems([]);
        setInactiveModal(false);
    };

    const addProjectToClient = async () => {
        //add put for clients add project to client
        const {
            // dispatch,
            accountId,
        } = props;
        // await dispatch(
        //     DraperyModule.addDraperyOrderItem({
        //         ...draperyOrderItem,
        //         ...draperyOrderDesign,
        //         accountId,
        //         clientId: clientInfo.id,
        //         itemIds: clientInfo.itemIds,
        //     })
        // );
        await addDraperyOrderItem({
            ...draperyOrderItem,
            ...draperyOrderDesign,
            accountId,
            clientId: clientInfo.id,
            itemIds: clientInfo.itemIds,
        });
        setClientProjectModal(false);
        setClientInfo({ id: 0, itemIds: [] });
    };

    const openItemModal = (item: MyShopItem | null = null) => {
        if (item) {
            const { itemClassId, id } = item;

            switch (itemClassId) {
                case ItemClasses.Standard:
                case ItemClasses.FabricsOrLining: {
                    setActiveModal(ModalName.StandardItem);
                    break;
                }
                case ItemClasses.Drapery: {
                    setActiveModal(ModalName.DraperyItem);
                    break;
                }
                default: {
                    setActiveModal("");
                    break;
                }
            }
            setSelectedItemId(id);
            if (itemClassId) {
                setActiveItemClassId(itemClassId);
            }
        } else {
            setActiveModal(ModalName.StandardItem);
            setActiveItemClassId(ItemClasses.Standard);
            setSelectedItemId(null);
        }
    };

    const onItemClick = (val: string) => {
        switch (val) {
            case "makeInactive":
                if (
                    checkedItems.some(
                        (checkedItem) => checkedItem.name === "Drapery"
                    )
                ) {
                    break;
                }
                setInactiveModal(true);
                break;
            case "edit":
                if (checkedItems.length === 1) {
                    handleRowClick({
                        rowId: checkedItems[0].id,
                        iClassId: checkedItems[0].itemClassId,
                    });
                }
                break;
            case "addToClient":
                break;
            case "manageCategories":
                props.history.push("/myshop/categories");
                break;
        }
    };

    const findParents = (
        item: Category | undefined,
        categories: Category[]
    ): Category[] => {
        if ((item && item.parentCategoryId === null) || !item) return [];
        const parentFound = categories.find(
            (cat) => cat.id === item.parentCategoryId
        );
        if (!parentFound) return [];
        return [parentFound, ...findParents(parentFound, categories)];
    };

    // Search functions
    const onSearchItemClick = async (
        id: number | undefined,
        name: string,
        activeOnly: boolean | undefined
    ) => {
        // const filterItems = allItems.filter((obj) => obj.id === id);
        let filterItems;
        let resulItems: any[] = [];
        if (name) {
            filterItems = filterRows(name);
            resulItems = [
                ...filterItems.searchFilterName,
                ...filterItems.searchFilterItem,
                ...filterItems.searchFilterSupplier,
                ...filterItems.searchSalesDescription,
            ].filter((item) => {
                const itemCategory = categories.find(
                    (cat) => cat.id === item.categoryId
                );
                return activeOnly
                    ? item.isActive === activeOnly &&
                          itemCategory &&
                          itemCategory.isActive
                    : true;
            });
        } else {
            resulItems = await getAllItems({
                isActiveOnly: activeOnly || undefined,
            });
        }
        const allCategories = name
            ? [...categories]
            : await getAllCategories({ isActiveOnly: activeOnly || undefined });

        // if (!filterItems || arr.length === 0) {
        //     const resetRows = createItemRows(allCategories, suppliers, allItems);
        //     setItemRows(resetRows);
        //     return;
        // }

        const filteredCategories = allCategories.filter((cat) => {
            return resulItems.some((item) => cat.id === item.categoryId);
        });

        const result = categories
            .filter((cat) =>
                resulItems.some((item) => cat.id === item.categoryId)
            )
            .map((item) => findParents(item, allCategories));
        let flattened = Array.prototype.concat.apply([], result);
        flattened = [...flattened, ...filteredCategories].filter(
            (value, index, arr) =>
                index === arr.findIndex((t) => t.id === value.id)
        );

        const searchRows = createItemRows(
            name ? flattened : allCategories,
            suppliers,
            name
                ? resulItems.filter(
                      (value, index, arr) =>
                          index === arr.findIndex((t) => t.id === value.id)
                  )
                : resulItems
        );

        setItemRows(searchRows);
        setSearch(name);
    };

    const filterRows = (search: string) => {
        const searchFilterName = allItems.filter((obj) => {
            // Convert the object's name to lowercase for a case-insensitive comparison
            return (
                obj.name &&
                obj.name.toLowerCase().includes(search.toLowerCase())
            );
        });
        const searchFilterItem = allItems.filter((obj) => {
            return (
                obj.itemNumber &&
                obj.itemNumber.toLowerCase().includes(search.toLowerCase())
            );
        });
        const searchFilterSupplier = allItems.filter((obj) => {
            return (
                obj.supplier &&
                obj.supplier.toLowerCase().includes(search.toLowerCase())
            );
        });

        const searchSalesDescription = allItems.filter((obj) => {
            return (
                obj.orderingDescription &&
                obj.orderingDescription
                    .replace(/[\n\r]/g, "")
                    .toLowerCase()
                    .includes(search.toLowerCase())
            );
        });

        return {
            searchFilterName,
            searchFilterItem,
            searchFilterSupplier,
            searchSalesDescription,
        };
    };

    const handleSearch = async (search: string) => {
        const allCategories = await getAllCategories();
        if (allItems.length === 0) {
            return;
        }

        const filterResultFinal = allItems.filter((r) => {
            const {
                name,
                itemNumber,
                salesDescription,
                orderingDescription,
                supplier,
            } = r;
            const splitSalesDescription =
                salesDescription && salesDescription.split("\n").join(" ");
            const splitOrderDescription =
                orderingDescription &&
                orderingDescription.split("\n").join(" ");
            if (
                (name && name.toLowerCase().includes(search.toLowerCase())) ||
                (itemNumber && itemNumber.includes(search)) ||
                (splitSalesDescription &&
                    splitSalesDescription
                        .toLowerCase()
                        .includes(search.toLowerCase())) ||
                (splitOrderDescription &&
                    splitOrderDescription
                        .toLowerCase()
                        .includes(search.toLowerCase())) ||
                (supplier &&
                    supplier.toLowerCase().includes(search.toLowerCase()))
            ) {
                return true;
            }
        });
        let newItems = filterResultFinal.map((i) => {
            const category = allCategories.find(
                (cat) => cat.id === i.categoryId
            );
            const subCategoryParent = allCategories.find((cat: Category) => {
                if (category) return cat.id === category.parentCategoryId;
                return false;
            });

            return {
                id: i.id,
                displayName: i.name,
                tags: i.name,
                categorie: (i.categoryId && category && category.name) || "",
                parentCategoryId:
                    (subCategoryParent && subCategoryParent.name) || "",
                columnType: "shop",
            };
        });
        return [...newItems];
    };

    const handleFilter = async (filterVals: FilterValues) => {
        const allItemsFilter = activeOnly
            ? await getAllItems({ isActiveOnly: true })
            : await getAllItems({ isActiveOnly: false });
        let filteredCategories = categories;
        let filteredSuppliers = suppliers;
        if (filterVals.category) {
            filteredCategories = categories.filter((cats) => {
                if (filterVals.category && cats.id === filterVals.category.id) {
                    return true;
                }
            });
        }
        if (filterVals.supplier) {
            filteredSuppliers = suppliers.filter((supps) => {
                if (
                    filterVals.supplier &&
                    supps.id === filterVals.supplier.id
                ) {
                    return true;
                }
            });
        }
        const filteredItems = allItemsFilter.filter((obj) => {
            let amountRetail: string = "";
            let amountTrades: string = "";
            if (
                obj.retailPricingMethod === "markUpCostRetail" &&
                priceRadio === "retail"
            ) {
                amountRetail = (
                    Number(obj.cost) +
                    (Number(obj.cost) * Number(obj.retailPricingValue)) / 100
                ).toFixed(2);
            }
            if (
                obj.retailPricingMethod === "discountMsrpRetail" &&
                priceRadio === "retail"
            ) {
                amountRetail = (
                    Number(obj.msrp) -
                    (Number(obj.msrp) * Number(obj.retailPricingValue)) / 100
                ).toFixed(2);
            }
            if (
                obj.retailPricingMethod === "costPlusRetail" &&
                priceRadio === "retail"
            ) {
                amountRetail = (
                    Number(obj.cost) + Number(obj.retailPricingValue)
                ).toFixed(2);
            }
            if (
                obj.tradePricingMethod === "costPlusTrade" &&
                priceRadio === "trade"
            ) {
                amountTrades = (
                    Number(obj.cost) + Number(obj.tradePricingValue)
                ).toFixed(2);
            }
            if (
                obj.retailPricingMethod === "rateRetail" &&
                priceRadio === "retail"
            ) {
                amountRetail = Number(obj.retailPricingValue).toFixed(2);
            }
            if (
                obj.tradePricingMethod === "markUpCostTrade" &&
                priceRadio === "trade"
            ) {
                amountTrades = (
                    Number(obj.cost) +
                    (Number(obj.cost) * Number(obj.tradePricingValue)) / 100
                ).toFixed(2);
            }
            if (
                obj.tradePricingMethod === "discountMsrpTrade" &&
                priceRadio === "trade"
            ) {
                amountTrades = (
                    Number(obj.msrp) -
                    (Number(obj.msrp) * Number(obj.tradePricingValue)) / 100
                ).toFixed(2);
            }
            if (
                obj.tradePricingMethod === "rateTrade" &&
                priceRadio === "trade"
            ) {
                amountTrades = Number(obj.tradePricingValue).toFixed(2);
            }
            const finalPrice = +amountRetail ? +amountRetail : +amountTrades;

            let costValidate =
                (!filterVals.maxCost || finalPrice <= filterVals.maxCost) &&
                (!filterVals.minCost || finalPrice >= filterVals.minCost);
            let activeValidate =
                filterVals.activeType === "all" ||
                obj.isActive === (filterVals.activeType === "active");
            let supplierValidate =
                !filteredSuppliers.length ||
                filteredSuppliers.some((s) => s.id === obj.supplierId);
            let categoryValidate =
                !filteredCategories.length ||
                filteredCategories.some((s) => s.id === obj.categoryId);

            return (
                costValidate &&
                activeValidate &&
                supplierValidate &&
                categoryValidate
            );
        });

        const finalRows = createItemRows(categories, suppliers, filteredItems);

        setItemRows(finalRows);
    };

    const clearFilter = () => {
        const clearItemRows = createItemRows(categories, suppliers, allItems);

        setItemRows(clearItemRows);
    };
    useEffect(() => {
        fetchAllWrapper();
    }, [fetchAll]);

    const handleSearchClient: SearchSelectProps["onSearch"] = async (
        search
    ) => {
        const { accountId } = props;
        const res = await searchClients({ text: search, accountId });
        if (res.error) {
            return [];
        }
        return res.map((r: any) => ({
            id: r.id,
            displayName:
                (r.parent ? r.parent.displayName + " | " : "") + r.displayName,
        }));
    };

    const handleSearchSelectChange = (id: number) => {
        const clientId = {
            id,
            itemIds: checkedItems.map((item) => item.id),
        };

        setClientInfo(clientId);
    };

    const closeAddSupplierModal = () => {
        setIsAddSupplierModalOpen(false);
    };

    const handleOpenModalClick = () => {
        setIsAddSupplierModalOpen(true);
    };

    useEffect(() => {
        const html = document.querySelector("html")!;
        if (
            activeModal === ModalName.StandardItem ||
            ModalName.DraperyItem === activeModal ||
            props.isOpenModal
        ) {
            html.style.position = "fixed";
            html.style.width = "100%";
        } else {
            html.style.position = "static";
            html.style.width = "initial";
        }
    }, [activeModal, props.isOpenModal]);

    useEffect(() => {
        if (rippleFoldPopUp && rippleFoldPopUp === "true") {
            let foundDraperyItem;
            for (const cat of itemRows) {
                if (cat.items && cat.items.length > 0) {
                    const found = cat.items.find((i) => i.name === "Drapery");
                    if (found) {
                        foundDraperyItem = found;
                    }
                }
            }

            if (foundDraperyItem) {
                openItemModal(foundDraperyItem);
                setActiveModal(ModalName.DraperyItem);
                setTabIndex(1);

                const arrayForHighLight = [
                    "ripplefold",
                    "beadedChain",
                    "standardCorner",
                ];
                setHighLightInput(arrayForHighLight);

                setTimeout(() => {
                    props.history.replace("/myShop");
                }, 500);
            }
        }
    }, [itemRows]);

    const createSupplier = async (supplier: SuppliersModule.Supplier) => {
        const { accountId } = props;
        const res = await addSupplier({
            ...supplier,
            accountId,
        });

        if (res.error) {
            if (res.msg === "User name not unique.") {
                let errors = { displayName: "Display Name already in use" };
                throw FormValidationError.fromErrorDictionary(errors);
            } else {
                throw new Error(res.msg);
            }
        }
        setSupplierName(res.displayName);
        setSupplierId(res.id);
        loadSuppliers();
    };

    const loadSuppliers = async () => {
        // const { dispatch, accountId } = props;
        const res: any = await getAllSuppliers();
        if (!res.error) {
            const convertToSortTableRowInfo = (
                supplier: SuppliersModule.BasicSupplier
            ) => {
                const rowInfo: SortTableRowInfo = {
                    id: supplier.id,
                    active: supplier.active,
                    avatarId: supplier.avatarId,
                    displayName: supplier.displayName,
                    companyName: supplier.companyName,
                    email: supplier.shippingAddress.email,
                    cellPhone: supplier.shippingAddress.cellPhone,
                    homePhone: supplier.shippingAddress.homePhone,
                    officePhone: supplier.shippingAddress.officePhone,
                };
                return rowInfo;
            };

            setSuppliers(res.payload.map(convertToSortTableRowInfo));
        }
    };

    // RENDER COMPONENTS
    const columnComponents: ReactElement[] = [
        <div
            key="checkbox-title"
            className="justify-center self-center items-center col-span-1 flex h-full px-4"
        >
            <Checkbox
                onChange={() => {
                    if (isChecked) {
                        setIsChecked(false);
                        setCheckedItems([]);
                    } else {
                        setCheckedItems(allItems);
                        setIsChecked(true);
                    }
                }}
                checked={isChecked}
            />
        </div>,
        <div
            key="item-name"
            className="justify-center self-center items-center col-span-3 flex h-full px-4 cursor-pointer font-medium "
            onClick={() => handleSort("name")}
        >
            <p className="text-center flex flex-row items-center text-[#222222] text-[14px] ">
                ITEM NAME{" "}
                {sortType.includes("name") && (
                    <FaArrowDown
                        className={`ml-2 h-3 w-3 ${
                            sortType.includes("_desc") ? "rotate-180" : ""
                        }`}
                    />
                )}
            </p>
        </div>,

        <div
            key="item-number"
            className="justify-center self-center items-center col-span-1 flex h-full px-4 cursor-pointer font-medium"
            onClick={() => handleSort("itemNumber")}
        >
            <p className="text-center flex flex-row items-center text-[#222222] text-[14px]">
                ITEM #
                {sortType.includes("itemNumber") && (
                    <FaArrowDown
                        className={`ml-2 h-3 w-3 ${
                            sortType.includes("_desc") ? "rotate-180" : ""
                        }`}
                    />
                )}
            </p>
        </div>,
        <div
            key="supplier-title"
            className="justify-center self-center items-center col-span-2 flex h-full px-4 cursor-pointer font-medium"
            onClick={() => handleSort("supplier")}
        >
            <p className="text-center flex flex-row items-center text-[#222222] text-[14px]">
                SUPPLIER
                {sortType.includes("supplier") && (
                    <FaArrowDown
                        className={`ml-2 h-3 w-3 ${
                            sortType.includes("_desc") ? "rotate-180" : ""
                        }`}
                    />
                )}
            </p>
        </div>,
        <div
            key="purchase-description"
            className="justify-center self-center items-center col-span-3 flex h-full px-4 cursor-pointer font-medium"
            onClick={() => handleSort("orderingDescription")}
        >
            <p className="text-center flex flex-row items-center text-[#222222] text-[14px]">
                ORDERING DESCRIPTION
                {sortType.includes("orderingDescription") && (
                    <FaArrowDown
                        className={`ml-2 h-3 w-3 ${
                            sortType.includes("_desc") ? "rotate-180" : ""
                        }`}
                    />
                )}
            </p>
        </div>,
        <div
            key="cost-title"
            className="justify-center self-center items-center col-span-1 flex h-full px-4 cursor-pointer font-medium"
            onClick={() => handleSort("cost")}
        >
            <p className="text-center flex flex-row items-center text-[#222222] text-[14px]">
                RETAIL PRICE
                {sortType.includes("retailPricingValue") && (
                    <FaArrowDown
                        className={`ml-2 h-3 w-3 ${
                            sortType.includes("_desc") ? "rotate-180" : ""
                        }`}
                    />
                )}
            </p>
        </div>,
        <div
            key="cost-title"
            className="justify-center self-center items-center col-span-1 flex h-full px-4 cursor-pointer font-medium"
            onClick={() => handleSort("tradePricingValue")}
        >
            <p className="text-center flex flex-row items-center text-[#222222] text-[14px]">
                TRADE PRICE
                {sortType.includes("cost") && (
                    <FaArrowDown
                        className={`ml-2 h-3 w-3 ${
                            sortType.includes("_desc") ? "rotate-180" : ""
                        }`}
                    />
                )}
            </p>
        </div>,
    ];

    const renderItemRow = (item: MyShopItem) => {
        let amountRetail: string = "";
        let amountTrades: string = "";
        const isChecked = checkedItems.find((x) => x.id === item.id)
            ? true
            : false;
        if (item.retailPricingMethod === "markUpCostRetail") {
            amountRetail = (
                Number(item.cost) +
                (Number(item.cost) * Number(item.retailPricingValue)) / 100
            ).toFixed(2);
        }
        if (item.retailPricingMethod === "discountMsrpRetail") {
            amountRetail = (
                Number(item.msrp) -
                (Number(item.msrp) * Number(item.retailPricingValue)) / 100
            ).toFixed(2);
        }
        if (item.retailPricingMethod === "costPlusRetail") {
            amountRetail = (
                Number(item.cost) + Number(item.retailPricingValue)
            ).toFixed(2);
        }
        if (item.tradePricingMethod === "costPlusTrade") {
            amountTrades = (
                Number(item.cost) + Number(item.tradePricingValue)
            ).toFixed(2);
        }
        if (item.retailPricingMethod === "rateRetail") {
            amountRetail = Number(item.retailPricingValue).toFixed(2);
        }
        if (item.tradePricingMethod === "markUpCostTrade") {
            amountTrades = (
                Number(item.cost) +
                (Number(item.cost) * Number(item.tradePricingValue)) / 100
            ).toFixed(2);
        }
        if (item.tradePricingMethod === "discountMsrpTrade") {
            amountTrades = (
                Number(item.msrp) -
                (Number(item.msrp) * Number(item.tradePricingValue)) / 100
            ).toFixed(2);
        }
        if (item.tradePricingMethod === "rateTrade") {
            amountTrades = Number(item.tradePricingValue).toFixed(2);
        }
        return (
            <Fragment>
                <div
                    key={item.id}
                    className={`justify-center self-center items-center col-span-1 flex h-full px-4`}
                >
                    <Checkbox
                        checked={isChecked}
                        onChange={() => checkItem(item)}
                    />
                </div>
                <div
                    key={item.name}
                    className={`self-center justify-center items-center col-span-3 flex flex-row h-full ${
                        item.avatarId ? "pl-0" : "pl-4"
                    } pr-4 overflow-hidden ${
                        !item.isActive && "text-twGrey italic"
                    }`}
                    data-class-id={item.itemClassId}
                    onClick={() => openItemModal(item)}
                    style={{ cursor: "pointer" }}
                >
                    {item.avatarId && (
                        <div className="mr-2">
                            <AvatarImage
                                avatarId={item.avatarId}
                                width={28}
                                height={28}
                                fontSize={15}
                            />
                        </div>
                    )}
                    <p className="">
                        {item.name || ""} {!item.isActive && "(inactive)"}
                    </p>
                </div>
                <div
                    key={item.itemNumber || `${item.id}ItemNumber`}
                    className="self-center items-center col-span-1 flex h-full px-2 overflow-hidden"
                    data-class-id={item.itemClassId}
                >
                    <p
                        className="w-full overflow-hidden"
                        style={{ lineClamp: 1, textOverflow: "ellipsis" }}
                    >
                        {item.itemNumber || ""}
                    </p>
                </div>
                <div
                    key={item.supplierId || `${item.id}Supplier`}
                    className="self-center items-center col-span-2 flex h-full px-2 overflow-hidden"
                >
                    <p
                        className="w-full overflow-hidden"
                        style={{ lineClamp: 1, textOverflow: "ellipsis" }}
                    >
                        {item.supplier || ""}
                    </p>
                </div>

                <div
                    key={item.orderingDescription}
                    className="self-center justify-center items-center col-span-3 flex h-full px-2 overflow-hidden"
                >
                    <p
                        className="w-full overflow-hidden"
                        style={{ lineClamp: 1, textOverflow: "ellipsis" }}
                    >
                        {item.orderingDescription || ""}
                    </p>
                </div>
                <div
                    key={`cost-${item.id}`}
                    className="self-center items-center col-span-1 flex h-full px-2 overflow-hidden"
                >
                    <p className="">
                        {item.retailPricingValue ? `$${amountRetail}` : ""}
                    </p>
                </div>
                <div
                    key={`tradecost-${item.id}`}
                    className="self-center items-center col-span-1 flex h-full px-2 overflow-hidden"
                >
                    <p className="">
                        {item.tradePricingValue ? `$${amountTrades}` : ""}
                    </p>
                </div>
            </Fragment>
        );
    };

    // FINAL RENDER
    return (
        <PageContent className="myshop">
            <PageHeader
                activeOnly={activeOnly}
                title="My Shop"
                onItemClick={onSearchItemClick}
                onSearch={handleSearch}
                onFilter={handleFilter}
                suppliers={suppliers}
                categories={categories}
                clearFilter={clearFilter}
                setClientInfo={setClientInfo}
                inputRef={inputRef}
                setActiveOnly={setActiveOnly}
                priceRadio={priceRadio}
                setPriceRadio={setPriceRadio}
            ></PageHeader>
            <ContentSection>
                {/* Header buttons */}
                {/* <div className="mt-4"> */}
                {(activeModal === ModalName.StandardItem ||
                    props.isOpenModal) && (
                    <StandardItemModal
                        isModalOpen={true}
                        onModalClose={closeModal}
                        categories={categories}
                        suppliers={suppliers}
                        itemId={selectedItemId}
                        activeItemClassId={activeItemClassId}
                        reloadGrid={reloadGrid}
                        openModalClick={handleOpenModalClick}
                        handleCloseModal={props.handleCloseModal}
                        supplierName={supplierName}
                        setSupplierName={setSupplierName}
                        supplierId={supplierId}
                    />
                )}
                {activeModal === ModalName.FabricsOrLiningsItem && (
                    <FabricsOrLiningsItemModal
                        isModalOpen={true}
                        onModalClose={closeModal}
                        categories={categories}
                        suppliers={suppliers}
                        itemId={selectedItemId}
                        reloadGrid={reloadGrid}
                    />
                )}
                {activeModal === ModalName.DraperyItem && (
                    <DraperyItemModal
                        isModalOpen={true}
                        onModalClose={closeModal}
                        categories={categories}
                        suppliers={suppliers}
                        itemId={selectedItemId}
                        reloadGrid={reloadGrid}
                        isPleatModalOpen={false}
                        tabIndex={rippleFoldPopUp ? tabIndex : undefined}
                        highLightInput={highLightInput}
                    />
                )}
                {showDraperyModal && selectedItemId && (
                    <DraperyItemModal
                        isModalOpen={true}
                        onModalClose={closeModal}
                        categories={categories}
                        suppliers={suppliers}
                        itemId={selectedItemId}
                        reloadGrid={reloadGrid}
                        showDraperyModal={true}
                        history={props.history}
                        isPleatModalOpen={false}
                    />
                )}
                <ConfirmationModal
                    uiActive={inactiveModal}
                    uiOnConfirm={makeItemsInactive}
                    uiOnClose={() => setInactiveModal(false)}
                    uiOnRequestClose={() => setInactiveModal(false)}
                    uiCloseOnOutsideClick={true}
                    uiCloseOnEsc={true}
                >
                    <p>Are you sure you want to make these item(s) inactive?</p>
                </ConfirmationModal>
                {/* </div> */}
                {clientProjectModal && (
                    <div className="mt-4">
                        <AddToClientModal
                            uiActive={clientProjectModal}
                            uiOnConfirm={addProjectToClient}
                            uiOnClose={() => setClientProjectModal(false)}
                            uiOnRequestClose={() =>
                                setClientProjectModal(false)
                            }
                            uiCloseOnOutsideClick={true}
                            uiCloseOnEsc={true}
                            handleSearchClient={handleSearchClient}
                            handleSearchSelectChange={handleSearchSelectChange}
                            clientInfo={clientInfo}
                            setClientInfo={setClientInfo}
                        ></AddToClientModal>
                    </div>
                )}

                <div>
                    {" "}
                    <SupplierModal
                        accountId={props.accountId}
                        isModalOpen={isAddSupplierModalOpen}
                        onModalClose={closeAddSupplierModal}
                        resetOnClose
                        onSave={createSupplier}
                    />
                </div>

                {/* Grid */}
                <div>
                    <div
                        className="h-full w-full myShopTable"
                        style={{ minWidth: 800 }}
                    >
                        <style>
                            {`
                                @media screen and (max-width: 992px) {
                                    .myShopTable {
                                        min-width: 670px !important;
                                    }
                                }
                            `}
                        </style>
                        {/* Grid Header */}
                        <div className={`sticky top-[8.3rem] `}>
                            <div
                                className={`flex flex-row justify-between items-center pb-2 pt-1`}
                                style={{ backgroundColor: stickyBackround }}
                            >
                                <div className="flex flex-row ml-2">
                                    <Checkbox
                                        checked={!activeOnly}
                                        onChange={() => handleInactiveOnly()}
                                    />

                                    <p className="mb-0 text-[14px] text-[#222]">
                                        Include Inactive
                                    </p>
                                </div>
                                <div className="flex flex-row">
                                    <div className="mr-4">
                                        <ActionButton
                                            isProjectsChecked={true}
                                            label="Actions"
                                            onItemClick={onItemClick}
                                            showHint={false}
                                            showArchived={false}
                                            showUnarchived={false}
                                            checkedItems={checkedItems}
                                            setClientProjectModal={
                                                setClientProjectModal
                                            }
                                        />
                                    </div>
                                    <div className="mr-4">
                                        <AddButton
                                            title="Add New Item"
                                            onClick={() => openItemModal(null)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`grid grid-cols-12 h-14 border border-solid border-[#e2e2e2] divide-x divide-black/5 
                                ${!stickyBackround ? "mb-2" : ""} `}
                                style={{ backgroundColor: "#ecf6f9" }}
                            >
                                {columnComponents.map(
                                    (item: ReactElement, index: number) => {
                                        return (
                                            <Fragment
                                                key={`title-column-${index}`}
                                            >
                                                {item}
                                            </Fragment>
                                        );
                                    }
                                )}
                            </div>
                        </div>

                        <FadeLoader
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(50%,-50%)",
                                zIndex: 9999,
                                display: !isLoading ? "none" : "block",
                            }}
                            color="#3B97B1"
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />

                        {/* Grid Body */}

                        {/* Categories */}
                        {itemRows &&
                            itemRows
                                .filter((itemRow) => {
                                    if (
                                        (itemRow.items &&
                                            itemRow.items.length > 0) ||
                                        (itemRow &&
                                            itemRow.subcategories &&
                                            itemRow.subcategories.some(
                                                (subCat) =>
                                                    (subCat.items &&
                                                        subCat.items.length >
                                                            0) ||
                                                    false
                                            )) ||
                                        [
                                            "Fabrication",
                                            "Fabrics & Trims",
                                            "Linings",
                                        ].includes(itemRow.name)
                                    ) {
                                        return true;
                                    }
                                    return false;
                                })
                                .map((cat: any, index: number) => {
                                    return (
                                        <Fragment key={cat.id}>
                                            <TitleSection
                                                isActive={cat.isActive}
                                                title={cat.name}
                                                index={index}
                                            />
                                            {/* Category Items */}
                                            {cat.items.map((i: any) => {
                                                return (
                                                    <div
                                                        key={i.id}
                                                        className={`grid grid-cols-12 h-14 border-t-0  border border-solid text-[#000] border-[#e2e2e2] divide-x divide-black/5 hover:bg-[#F6F6F6] text-[14px]  ${
                                                            !cat.isActive
                                                                ? "pointer-events-none opacity-70 italic text-gray-500"
                                                                : ""
                                                        } `}
                                                    >
                                                        {renderItemRow(i)}
                                                    </div>
                                                );
                                            })}
                                            {/* Subcategories */}
                                            {cat.subcategories
                                                .filter((subCat: any) => {
                                                    if (
                                                        subCat.items &&
                                                        subCat.items.length > 0
                                                    ) {
                                                        return true;
                                                    }
                                                    return false;
                                                })
                                                .map((sc: any) => {
                                                    return (
                                                        <Fragment key={sc.id}>
                                                            <SubtitleSection
                                                                isActive={
                                                                    sc.isActive
                                                                }
                                                                title={sc.name}
                                                            />

                                                            {sc.items.map(
                                                                (i: any) => {
                                                                    return (
                                                                        <div
                                                                            key={
                                                                                i.id
                                                                            }
                                                                            className={`grid grid-cols-12 h-14 border-t-0 border border-solid text-[#000] border-[#e2e2e2] divide-x divide-black/5 hover:bg-[#F6F6F6] text-[14px]  ${
                                                                                !sc.isActive
                                                                                    ? "pointer-events-none opacity-70 italic text-gray-500"
                                                                                    : ""
                                                                            } `}
                                                                            data-class-id={
                                                                                i.itemClassId
                                                                            }
                                                                        >
                                                                            {renderItemRow(
                                                                                i
                                                                            )}
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </Fragment>
                                                    );
                                                })}
                                        </Fragment>
                                    );
                                })}
                    </div>
                </div>
            </ContentSection>
        </PageContent>
    );
};

function mapStateToProps(
    props: RootState,
    ownProps: ClientOwnProps
): ClientsProps {
    return {
        ...ownProps,
        accountId: props.account.id,
    };
}
export default connect(mapStateToProps)(MyShop);

const sortItems = (
    property: string,
    itemRows: MyShopItem[],
    isReverse = false
) => {
    let sortedItems = [...itemRows];

    sortedItems.sort((a, b) => {
        // check if the property values are strings and compare them
        if (
            typeof a[property] === "string" &&
            typeof b[property] === "string"
        ) {
            return isReverse
                ? b[property].localeCompare(a[property])
                : a[property].localeCompare(b[property]);
        }

        // check if the property values are numbers and compare them
        if (
            typeof a[property] === "number" &&
            typeof b[property] === "number"
        ) {
            return isReverse
                ? b[property] - a[property]
                : a[property] - b[property];
        }

        return 0; // return 0 as a fallback, leaving items as they were
    });

    return sortedItems;
};

const sortCategories = (
    property: string,
    categories: MyShopCategory[],
    isReverse = false
) => {
    let sortedCategories = [...categories];

    sortedCategories.sort((a: MyShopCategory, b: MyShopCategory) => {
        if (!a.items || !b.items) {
            return 0;
        }

        let aVal = a.items.length > 0 ? a.items[0][property] : null;
        let bVal = b.items.length > 0 ? b.items[0][property] : null;

        if (aVal && bVal) {
            if (typeof aVal === "string" && typeof bVal === "string") {
                return isReverse
                    ? bVal.localeCompare(aVal)
                    : aVal.localeCompare(bVal);
            }

            if (typeof aVal === "number" && typeof bVal === "number") {
                return isReverse ? bVal - aVal : aVal - bVal;
            }
        }

        if (!a.subcategories || !b.subcategories) {
            return 0;
        }

        // Sort subcategories
        a.subcategories.sort((a: MyShopCategory, b: MyShopCategory) => {
            if (
                typeof a[property] === "string" &&
                typeof b[property] === "string"
            ) {
                return isReverse
                    ? b[property].localeCompare(a[property])
                    : a[property].localeCompare(b[property]);
            }

            if (
                typeof a[property] === "number" &&
                typeof b[property] === "number"
            ) {
                return isReverse
                    ? b[property] - a[property]
                    : a[property] - b[property];
            }
            return 0;
        });

        b.subcategories.sort((a: MyShopCategory, b: MyShopCategory) => {
            if (
                typeof a[property] === "string" &&
                typeof b[property] === "string"
            ) {
                return isReverse
                    ? b[property].localeCompare(a[property])
                    : a[property].localeCompare(b[property]);
            }

            if (
                typeof a[property] === "number" &&
                typeof b[property] === "number"
            ) {
                return isReverse
                    ? b[property] - a[property]
                    : a[property] - b[property];
            }
            return 0;
        });

        return 0; // return 0 as a fallback, leaving categories as they were
    });

    return sortedCategories;
};
