import React, { FC, memo, useEffect } from "react";

import { AvatarImage } from "../UI/avatar";
// import { CSSTransition } from "react-transition-group";
import { FaPlus } from "react-icons/fa";
import { UserMenu } from "./user_menu";
import styled from "../../theme";
import { TransitionPopover } from "@ramble/ramble-ui";
import { useClearCache } from "react-clear-cache";

export interface HeaderProps {
    /**
     * User name
     */
    userName: string;
    /**
     * First name
     */
    firstName?: string;
    /**
     * Last name
     */
    lastName?: string;
    /**
     * Logo id
     */
    logoId?: string | null;
    /**
     * Admin access
     */
    isAdmin?: boolean;
    /**
     * Email
     */
    userEmail?: string;
    /**
     * AccountId
     */
    accountId?: number;

    isOpen?: boolean;

    handlePlusClick(): void;

    /**
     * Navigation callback
     * @param to Navigation value
     */
    onNavigateTo(to: string): void;
    onPlusClicked(e: React.MouseEvent<HTMLLIElement>): void;
    handleCloseModal(): void;
}

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    padding-left: 1em;

    > *:not(:last-child) {
        margin-left: 10px;
    }
`;

const AddButton = styled.div`
    position: absolute;
    // border-radius: 20px;
    box-shadow: 0px 8px 36px 0px #0000002b;
    overflow: hidden;
    top: 63px;
    right: 221px;
    animation: slidein 0.2s ease-in;
    ul {
        position: relative;
        z-index: 999999;
    }
    li {
        color: #fff;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        display: flex;
        width: 205px;
        padding: var(--8px, 8px);
        // border-top: 1px solid #eee;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        background-color: #3b97b1;
        height: 49px;
        // &:first-child {
        //     background-color: #3c8f9e;
        //     color: #fff;
        //     border-top: none;
        //     font-weight: 500;
        // }
        // &:not(:first-child) {
        background-color: #3b97b1;
        &:active {
            background-color: #377a8;
        }
        &:hover {
            background-color: #377a8d;
        }
        // }
        // &.active {
        //     background-color: #f6f6f6 !important;
        // }
    }
    &.transition-enter,
    &.transition-appear {
        opacity: 1;
        transform: scaleY(0.1);
        transform-origin: 100% 0;
    }

    &.transition-enter-active,
    &.transition-appear-active {
        transform: scaleY(1);
        transition: transform 0.1s ease;
    }

    &.transition-exit {
        opacity: 1;
        transform: scaleY(1);
        transform-origin: 100% 0;
    }

    &.transition-exit-active {
        opacity: 0.4;
        transform: scaleY(0.1);
        transition: transform 0.1s ease, opacity 0.1s ease;
    }
`;
const RectSvg = styled.svg`
    right: 10px;
    top: 1px;
    z-index: 9999;
    position: relative;
    left: 149px;
`;
const Avatar = styled(AvatarImage)`
    /* margin-top: 10px; */
`;

/**
 * Application header
 */
export const AppHeader: FC<HeaderProps> = memo((props) => {
    const { isLatestVersion, emptyCacheStorage } = useClearCache();
    let closeTimer: any;
    useEffect(() => {
        // Download Canny SDK
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src = "https://canny.io/sdk.js";

        script.onload = () => {
            // Use the Canny SDK to identify the current user
            (window as any).Canny("identify", {
                appID: "64b2f974cd4b0509deaedefd",
                user: {
                    email: props.userEmail,
                    name: props.userName,
                    id: props.accountId,
                },
            });
        };

        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, [props]);
    useEffect(() => {
        const li = document.querySelectorAll("li");
        const url = location.href.split("/")[3];
        li.forEach((li) => {
            if (li && li.dataset && li.dataset.num && li.dataset.num === url) {
                li.className = "active";
            }
        });
    });

    useEffect(() => {
        if (!isLatestVersion) {
            emptyCacheStorage();
        }
    }, [isLatestVersion]);

    const requestCloseMenu = () => {
        if (closeTimer) {
            clearTimeout(closeTimer);
        }
        closeTimer = setTimeout(closeMenu, 1000);
    };

    const closeMenu = () => {
        props.handleCloseModal();
        closeTimer = undefined;
    };
    const rect = (): JSX.Element => {
        return (
            <RectSvg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="7"
                viewBox="0 0 16 7"
                fill="none"
            >
                <path d="M8 0L16 7H0L8 0Z" fill="#3B97B1" />
            </RectSvg>
        );
    };
    return (
        <Container>
            <button
                className="border border-white rounded-full p-2 border-2 cursor-pointer"
                onClick={props.handlePlusClick}
            >
                <FaPlus />
            </button>
            <TransitionPopover
                uiActive={props.isOpen}
                uiDistanceAway={20}
                uiOffset={-198}
                uiAutoPosition={false}
                uiTargetEl="user-menu"
                uiOnRequestClose={props.handlePlusClick}
                uiTransitionTimeouts={100}
                uiPosition="bottom right"
            >
                <AddButton onMouseLeave={requestCloseMenu}>
                    <>
                        {rect()}
                        <ul>
                            <li
                                data-num="clients"
                                onClick={(e) => props.onPlusClicked(e)}
                            >
                                Add New Client
                            </li>
                            <li
                                data-num="suppliers"
                                onClick={(e) => props.onPlusClicked(e)}
                            >
                                Add New Supplier
                            </li>
                            <li
                                data-num="calculator"
                                onClick={(e) => props.onPlusClicked(e)}
                            >
                                Add New Drapery Order
                            </li>
                            <li
                                data-num="myshop"
                                onClick={(e) => props.onPlusClicked(e)}
                            >
                                Add New Item to My Shop
                            </li>
                        </ul>
                    </>
                </AddButton>
            </TransitionPopover>{" "}
            <Avatar
                avatarId={props.logoId}
                firstName={props.firstName}
                lastName={props.lastName}
                width={34}
                height={34}
                fontSize={20}
                uiType="account"
                isSquareImgHolder={true}
            />
            <UserMenu
                label={`Hello, ${props.userName}`}
                onItemClick={props.onNavigateTo}
                isAdmin={props.isAdmin}
            />
        </Container>
    );
});
