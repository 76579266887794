import axiosInstance from "./axios-instance";
const updateUsersPassword = async (payload: any) => {
    try {
        const response = await axiosInstance.post(
            `/users/editPassword`,
            payload
        );

        return response.data;
    } catch (error) {
        console.error(`Error while updating users password:`, error);
        throw error;
    }
};

const handleUpdateUser = async (payload: any) => {
    try {
        const response = await axiosInstance.post(`/users/updateUser`, payload);
        return response.data;
    } catch (error) {
        console.error(`Error while updating user:`, error);
        throw error;
    }
};

export { updateUsersPassword, handleUpdateUser };
