import { ApplicationModule, RootState, UserModule } from "../redux";
import { ClickOutside, dimmable } from "@ramble/ramble-ui";
import { DispatchProp, connect } from "react-redux";
import React, { PureComponent } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import styled, { theme } from "../theme";

import { AccountSettingsContainer } from "../pages/account_settings";
import { AdminContainer } from "../pages/admin";
import { AppHeader } from "../components/app_header/app_header";
import { ApplicationError } from "../components/application/error";
import { ApplicationLoader } from "../components/application/loader";
import { CalculatorContainer } from "../components/calculator/calculator";
import { ClientsContainer } from "../pages/clients";
import { Overview } from '../pages/overview';
import MyShopPage from "../pages/my-shop";
import { NotificationStackContainer } from "../components/notifications/notification_stack";
import QuipaHorizontal from "../assets/icons/quipa_horizontal.svg";
import { RealtimeStatus } from "../redux/modules/application";
import { Sidebar } from "../components/sidebar/sidebar";
import { SuppliersContainer } from "../pages/suppliers";
import SupportModal from "../modals/support-modal";
import { UserSettingsContainer } from "../pages/user_settings";

interface MainApplicationContainerProps {
    uiSidebarFull: boolean;
}

/**
 * General application container
 */
const MainApplicationContainer = styled.div<MainApplicationContainerProps>`
    height: 100%;
    width: 100%;
    margin: 0;
    display: grid;
    grid-template-columns: ${theme.elements.sidebarSmallSize}px 1fr;
    grid-template-rows: ${theme.elements.headerFullSize}px 1fr;
    grid-template-areas:
        "sidebar_control header"
        "sidebar main";
    grid-gap: 0 0;
    justify-items: stretch;
    align-items: stretch;
`;

const HeaderContent = styled.div`
    grid-area: header;
    background: ${theme.colors.primary};
    color: ${theme.getContrastTextColor(theme.colors.primary)};
    position: fixed;
    top: 0;
    left: 0;
    height: 65px;
    width: 100%;
    z-index: 999;
`;

const QuipaLogoContainer = styled.div`
    cursor: pointer;
`;

const QuipaLogo = styled(QuipaHorizontal)`
    float: left;
    margin-left: 11px;
    height: 65px;
`;

const MainContent = styled.main`
    grid-area: main;
    background: #fff;
    margin: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
    ${dimmable};
    height: auto;
`;

const SidebarContent = styled.aside`
    grid-area: sidebar;
    background: ${theme.colors.primary};
    color: ${theme.getContrastTextColor(theme.colors.primary)};
    position: fixed;
    left: 0;
    top: 65px;
    height: 100%;
    z-index: 500;
`;

// tslint:disable-next-line:no-empty-interface
export interface ApplicationOwnProps extends RouteComponentProps { }

interface ApplicationProps extends ApplicationOwnProps {
    /**
     * Auth token
     */
    token?: string;
    /**
     * True when app was initially loaded
     */
    appLoaded: boolean;
    /**
     * App connection status
     */
    realtimeStatus: RealtimeStatus;
    /**
     * User full name
     */
    userName: string;
    /**
     * User role
     */
    userRole: "admin" | "office_worker";
    /**
     * First name
     */
    firstName: string;
    /**
     * Last name
     */
    lastName: string;
    /**
     * LogoId
     */
    logoId?: string | null;
    /**
     * Account Id
     */
    accountId: number;
    /**
     * Email
     */
    userEmail: string;
}

interface State {
    /**
     * True when sidebar is fully expanded
     */
    fullSidebar: boolean;
    /**
     * Initial loading error
     */
    loadingError?: string;
    /**
     * Support Modal
     */
    isSupportModalOpen?: boolean;

    isOpen: boolean;

    isOpenModal: boolean;
}

export class Application extends PureComponent<
    ApplicationProps & DispatchProp,
    State
> {
    public state: State = {
        fullSidebar: false,
        isOpen: false,
        isOpenModal: false,
    };

    public async componentDidMount(): Promise<void> {
        const { dispatch, appLoaded, history, token } = this.props;
        // Bail if application already loaded
        if (appLoaded || !token) {
            return;
        }
        const res = await dispatch(ApplicationModule.initialAppSync(token));
        if (res.error) {
            if (res.payload.code === 401) {
                history.replace({
                    pathname: "/login",
                    state: {
                        redirectAfterLogin: location.pathname,
                        redirectAfterLoginSearch: location.search,
                    },
                });
            } else {
                this.setState({
                    loadingError: res.payload.message,
                });
            }
        } else if (!res.payload.account.loginAllowed) {
            // credit card required
            history.replace({
                pathname: "/card",
                state: {
                    redirectAfterLogin: location.pathname,
                    redirectAfterLoginSearch: location.search,
                },
            });
        }
    }

    public render(): JSX.Element {
        const { fullSidebar, loadingError } = this.state;
        const {
            appLoaded,
            realtimeStatus,
            userName,
            firstName,
            lastName,
            logoId,
            userRole,
            userEmail,
            accountId,
        } = this.props;
        if (!appLoaded) {
            /**
             * Display loading header or critical error header depending of current realtimeStatus:
             * If "connecting' display header with "Connecting" status
             * If "connected" it means that socket connection was established, but didn't authenticated yet, so display "Authenticating"
             * If "disconnected" and we're still here then either connection to socket failed or token refresh request failed
             * with non Token related error (means that token may be valid, but failed for some other reason)
             */
            if (realtimeStatus === "disconnected" || loadingError) {
                const message =
                    "Unable to load application. This caused either by an unreachable network connection or an error on our end";
                return <ApplicationError message={loadingError || message} />;
            }
            // If hasn't error (yet), display the spinner then
            const loadingText =
                realtimeStatus === "connecting"
                    ? "Connecting..."
                    : "Authenticating...";
            return <ApplicationLoader message={loadingText} />;
        }
        return (
            <MainApplicationContainer uiSidebarFull={fullSidebar}>
                <HeaderContent>
                    <QuipaLogoContainer onClick={this.goHome}>
                        <QuipaLogo />
                    </QuipaLogoContainer>
                    <AppHeader
                        userName={userName}
                        firstName={firstName}
                        lastName={lastName}
                        logoId={logoId}
                        userEmail={userEmail}
                        accountId={accountId}
                        onNavigateTo={this.handleNewNavigation}
                        isAdmin={userRole === "admin"}
                        onPlusClicked={this.handlePlusClicked}
                        isOpen={this.state.isOpen}
                        handlePlusClick={this.handlePlusClick}
                        handleCloseModal={this.handleCloseBtnModal}
                    />
                </HeaderContent>
                <SidebarContent
                    id="sidebar"
                    onMouseEnter={this.toggleSidebar}
                    onMouseLeave={this.toggleSidebar}
                >
                    {fullSidebar && (
                        <ClickOutside
                            rootNodeId="sidebar"
                            onOutsideClick={this.toggleSidebar}
                            ignoreIds="sidebar-control"
                        />
                    )}
                    <Sidebar
                        // expanded={fullSidebar}
                        onNavigateTo={this.handleNewNavigation}
                        activeValue={this.getActiveSidebarItem()}
                    />
                </SidebarContent>
                <MainContent>
                    <Switch>
                        <Redirect exact from="/" to="/overview" />
                        {/* tslint:disable-next-line: jsx-alignment */}
                        {
                            <Route
                                path="/calculator"
                                render={(props) => (
                                    <CalculatorContainer
                                        key={
                                            (props.location.state &&
                                                props.location.state.tempKey) ||
                                            "calc"
                                        }
                                        {...props}
                                        handleCloseModal={this.handleCloseModal}
                                    />
                                )}
                            />
                        }

                        <Route
                            path="/overview"
                            render={(props: RouteComponentProps) => (
                                <Overview
                                    isOpenModal={this.state.isOpenModal}
                                    handleCloseModal={this.handleCloseModal}
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            path="/clients"
                            render={(props: RouteComponentProps) => (
                                <ClientsContainer
                                    isOpenModal={this.state.isOpenModal}
                                    handleCloseModal={this.handleCloseModal}
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            path="/myshop"
                            render={(props: RouteComponentProps) => (
                                <MyShopPage
                                    isOpenModal={this.state.isOpenModal}
                                    handleCloseModal={this.handleCloseModal}
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            path="/suppliers"
                            render={(props: RouteComponentProps) => (
                                <SuppliersContainer
                                    isOpenModal={this.state.isOpenModal}
                                    handleCloseModal={this.handleCloseModal}
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            path="/admin_settings"
                            component={AdminContainer}
                        />
                        <Route
                            path="/account_settings"
                            component={AccountSettingsContainer}
                        />
                        <Route
                            path="/user_settings"
                            component={UserSettingsContainer}
                        />
                    </Switch>
                </MainContent>
                <NotificationStackContainer />
                {this.state.isSupportModalOpen && (
                    <SupportModal
                        isModalOpen={this.state.isSupportModalOpen}
                        onModalClose={this.toggleSupportModal}
                    />
                )}
            </MainApplicationContainer>
        );
    }

    private handleNewNavigation = (val: string): void => {
        const { history, dispatch, accountId } = this.props;
        switch (val) {
            case "support": {
                this.setState({ isSupportModalOpen: true });
                return;
            }
            case "logout": {
                dispatch(UserModule.logout());
                localStorage.setItem("isReloaded", "false");
                localStorage.setItem("refreshed", "false");
                localStorage.setItem(`logout-event-${accountId}`, 'logout' + Math.random());

                document.cookie.split(';').forEach(item => {
                    const cookieName = item.trim().split('=')[0];
                    if (cookieName.startsWith('crisp-client')) {
                        const cookieDomain = '.quipa.com';
                        const cookiePath = '/';

                        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${cookiePath}; domain=${cookieDomain};`;
                        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${cookiePath};`;
                    }
                });

                history.push("/login");
                return;
            }
        }
        history.push(`/${val}`);
    };

    private handlePlusClicked = async (
        e: React.MouseEvent<HTMLLIElement>
    ): Promise<void> => {
        const { history } = this.props;
        if (e.currentTarget.textContent === "Add New Client") {
            history.push("/clients", { tempKey: Math.random() });
            this.setState({ isOpenModal: true });
        } else if (e.currentTarget.textContent === "Add New Supplier") {
            history.push("/suppliers", { tempKey: Math.random() });
            this.setState({ isOpenModal: true });
        } else if (e.currentTarget.textContent === "Add New Drapery Order") {
            history.push("/calculator", { tempKey: Math.random() });
        } else {
            history.push("/myshop", { tempKey: Math.random() });
            this.setState({ isOpenModal: true });
        }
        this.setState({
            isOpen: false,
        });
    };
    private handlePlusClick = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };
    public handleCloseModal = () => {
        this.setState({ isOpenModal: false });
    };

    public handleCloseBtnModal = () => {
        this.setState({ isOpen: false });
    };
    private toggleSidebar = (): void => {
        this.setState({
            fullSidebar: !this.state.fullSidebar,
        });
    };

    private toggleSupportModal = (): void => {
        this.setState({
            isSupportModalOpen: !this.state.isSupportModalOpen,
        });
    };

    private goHome = (): void => {
        const { history } = this.props;
        history.push("/");
    };

    private getActiveSidebarItem(): string | undefined {
        const {
            location: { pathname },
        } = this.props;
        if (pathname === "/") {
            return "clients";
        } else {
            return pathname.replace(/[^0-9a-zA-Z]/g, "");
        }
    }
}

function mapStateToProps(
    state: RootState,
    ownProps: ApplicationOwnProps
): ApplicationProps {
    return {
        ...ownProps,
        appLoaded: state.application.loaded,
        token: state.user.token,
        realtimeStatus: state.application.realtimeStatus,
        userName: state.user.firstName,
        userRole: state.user.role,
        firstName: state.user.firstName,
        lastName: state.user.lastName,
        logoId: state.user.logoId,
        accountId: state.account.id,
        userEmail: state.user.email,
    };
}

export const ApplicationContainer = connect(mapStateToProps)(Application);
