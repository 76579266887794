import "cropperjs/dist/cropper.css";

import {
    Input,
    Loader,
    Modal,
    ModalCloseIcon,
    ModalContent,
    ModalHeader,
    PageDimmer,
} from "@ramble/ramble-ui";
import React, { ChangeEvent, createRef, PureComponent, RefObject } from "react";
import styled, { theme } from "../theme";

import Cropper from "react-cropper";
import PhotographIcon from "./../assets/icons/photograph.svg";
import TrashIconE from "./../assets/icons/trash.svg";

const noop = () => {
    return;
};

const StyledModal = styled(Modal)`
    width: 500px;
    height: 500px;
    flex-shrink: 0;
    position: relative;
    background: #fff;
`;

const ImageCropperWrapper = styled.div`
    .cropper-container {
        background: #f2f2f2;
    }
    .cropper-modal {
        background: #fff;
    }
    .rounded-image-select .cropper-view-box {
        border-radius: 50%;
        border: 3px solid #00b2ff;
    }
    .rounded-image-select .cropper-face {
        border-radius: 50%;
    }
    .cropper-point {
        color: inherit;
    }
`;

const NoImage = styled.label`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex: 0 0 auto;
    width: 250px;
    height: 250px;
    border: 1px dashed ${theme.colors.divider};
    overflow: hidden;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const StyledButton = styled.div`
    width: 100px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #3b97b1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
`;

const SliderContainer = styled.div`
    margin-top: 1em;
    width: 100%;
    text-align: center;
`;

const Slider = styled(Input)`
    border: none;

    &:focus-within {
        border: none;
    }
`;

const UploadInput = styled.input`
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    pointer-events: none;
`;

const RotateArrows = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 6px;
`;

const RotateArrowRight = styled.div`
    position: absolute;
    bottom: 11.5%;
    left: 31%;
    cursor: pointer;
    font-size: 30px;
    color: #3b97b1;
    transform: rotate(85deg);

    /* Chrome-specific styles */
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
        left: 31%;
    }

    /* Firefox-specific styles */
    @-moz-document url-prefix() {
        left: 25%;
    }
`;

const DragAndDropText = styled.div`
    color: #c1c1c1;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const ChooseFromComputer = styled.div`
    color: #92cddd;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
`;

const PhotographIconImg = styled(PhotographIcon)`
    width: 40px;
    height: 38px;
    margin-bottom: 1em;
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
`;

const TrashIconWrapper = styled.div`
    cursor: pointer;
`;

const TrashIcon = styled(TrashIconE)`
    width:40px;
    height:38px;
    background-size: cover;
    background-repeat: no-repeat;
    padding:5px;
    z-index:1;
    position:absolute;
    left:1%;
    bottom:1%;
})
`;

const AllowedSizeMessage = styled.p<AllowedSizeMessageProps>`
    width: 100%;
    text-align: center;
    color: red;
`;

export interface AvatarModalProps {
    header?: string;
    isRect?: boolean;
    ratio?: number;
    /**
     * Modal active flag
     */
    uiActive: boolean;
    /**
     * Current profile image
     */
    currentImage?: string;
    /**
     * Cancel editing
     */
    uiOnRequestClose(): void;
    /**
     * Save new image
     * @param file Image blob
     */
    onSave(file: Blob): Promise<void>;
}

interface AllowedSizeMessageProps {
    imageSizeExceeded: boolean;
    imageSizeExceededMax: boolean;
    imageUnsupported: boolean;
}

interface State {
    /**
     * Image data url for locally selected image
     */
    imageUrl?: string;
    image?: any;
    croppedImgSrc: any;
    imageSizeExceeded: boolean;
    imageSizeExceededMax: boolean;
    zoomSliderValue: number;
    imageUnsupported: boolean;
    isValidExtension: boolean;
    showMessage: boolean;
    messageText: string;
    messageType: "sizeExceeded" | "invalidExtension" | null;
    /**
     Crop info for ReactCrop, and rotate image
     */
    rotate: number;
    /**
     * Image submitting (via onSave) flag
     */
    submitting: boolean;
}

export class AvatarModal extends PureComponent<AvatarModalProps, State> {
    public state: State = {
        imageUrl: undefined,
        rotate: 0,
        submitting: false,
        croppedImgSrc: undefined,
        imageSizeExceeded: false,
        imageSizeExceededMax: false,
        zoomSliderValue: 0,
        imageUnsupported: false,
        isValidExtension: false,
        showMessage: false,
        messageText: "",
        messageType: null,
    };
    private cropperRef: RefObject<any> = createRef();

    private rotateRight = () => {
        this.setState(
            (prevState) => ({ rotate: prevState.rotate + 90 }),
            () => {
                if (
                    this.cropperRef &&
                    this.cropperRef.current &&
                    this.cropperRef.current.cropper
                ) {
                    this.cropperRef.current.cropper.rotate(90);
                    this.handleCropChange();
                }
            }
        );
    };
    public render(): JSX.Element {
        const { uiActive, isRect: isRectProp, header } = this.props;
        const {
            imageUrl,
            submitting,
            imageSizeExceeded,
            imageSizeExceededMax,
        } = this.state;
        const currentPath = window.location.pathname;
        const hasImage = !!imageUrl;
        const isRect = currentPath === "/account_settings" ? true : isRectProp;
        return (
            <>
                <PageDimmer uiActive={uiActive} />
                <StyledModal
                    uiActive={uiActive}
                    uiOnRequestClose={noop}
                    uiOnClose={this.onClose}
                >
                    {hasImage && (
                        <ModalHeader>
                            {header ? (
                                <>{header}</>
                            ) : (
                                <>
                                    {currentPath === "/account_settings"
                                        ? "Company Logo"
                                        : "Image Upload"}
                                </>
                            )}
                            <ModalCloseIcon onClick={this.close} />
                        </ModalHeader>
                    )}
                    <ModalContent
                        style={{
                            padding: "0px",
                            width: "520px",
                            height: "320px",
                            borderBottom: "none",
                        }}
                    >
                        {imageUrl && (
                            <ImageCropperWrapper
                                style={{ width: "500px", height: "320px" }}
                            >
                                <Cropper
                                    ref={this.cropperRef}
                                    src={imageUrl}
                                    style={{
                                        height: isRect ? 320 : "100%",
                                        width: "100%",
                                    }}
                                    guides={false}
                                    aspectRatio={isRect ? NaN : 1}
                                    viewMode={isRect ? 0 : 2}
                                    dragMode={isRect ? "crop" : "move"}
                                    className={
                                        isRect ? "" : "rounded-image-select"
                                    }
                                    minCropBoxWidth={isRect ? 320 : undefined}
                                    minCropBoxHeight={isRect ? 80 : undefined}
                                    toggleDragModeOnDblclick={false}
                                    initialAspectRatio={16 / 9}
                                />
                            </ImageCropperWrapper>
                        )}
                        {!imageUrl && (
                            <>
                                <ModalHeader>
                                    <ModalCloseIcon
                                        style={{ marginRight: "20px" }}
                                        onClick={this.close}
                                    />
                                </ModalHeader>
                                <NoImage
                                    onDragOver={(
                                        e: React.DragEvent<HTMLLabelElement>
                                    ) => e.preventDefault()}
                                    onDragEnter={(
                                        e: React.DragEvent<HTMLLabelElement>
                                    ) => e.preventDefault()}
                                    onDrop={(
                                        e: React.DragEvent<HTMLLabelElement>
                                    ) => this.onDrop(e)}
                                >
                                    <PhotographIconImg />
                                    <DragAndDropText>
                                        Drag and drop file here
                                    </DragAndDropText>
                                    <DragAndDropText>or</DragAndDropText>
                                    <ChooseFromComputer>
                                        Choose from computer
                                    </ChooseFromComputer>
                                    <UploadInput
                                        type="file"
                                        accept="image/*"
                                        onChange={this.onFileSelected}
                                    />
                                    {this.state.showMessage &&
                                        this.state.messageType ===
                                            "sizeExceeded" && (
                                            <AllowedSizeMessage
                                                imageSizeExceeded={
                                                    imageSizeExceeded
                                                }
                                                imageSizeExceededMax={
                                                    imageSizeExceededMax
                                                }
                                                imageUnsupported={false}
                                            >
                                                {this.state.messageText}
                                            </AllowedSizeMessage>
                                        )}
                                    {this.state.showMessage &&
                                        this.state.messageType ===
                                            "invalidExtension" && (
                                            <AllowedSizeMessage
                                                imageSizeExceeded={false}
                                                imageSizeExceededMax={false}
                                                imageUnsupported={
                                                    this.state.imageUnsupported
                                                }
                                            >
                                                {this.state.messageText}
                                            </AllowedSizeMessage>
                                        )}
                                </NoImage>
                            </>
                        )}
                    </ModalContent>
                    {hasImage && (
                        <>
                            <SliderContainer>
                                <Slider
                                    type="range"
                                    min={0}
                                    max={1000}
                                    step="1"
                                    value={this.state.zoomSliderValue}
                                    onChange={this.changeZoom}
                                    style={{ textAlign: "left" }}
                                />
                            </SliderContainer>
                            <RotateArrows>
                                <RotateArrowRight
                                    onClick={() => this.rotateRight()}
                                >
                                    &#8635;
                                </RotateArrowRight>
                            </RotateArrows>
                            <ButtonContainer>
                                <TrashIconWrapper onClick={this.close}>
                                    <TrashIcon />
                                </TrashIconWrapper>
                                <StyledButton onClick={this.saveCroppedImage}>
                                    {submitting ? (
                                        <Loader uiSize="1em" />
                                    ) : (
                                        "Save"
                                    )}
                                </StyledButton>
                            </ButtonContainer>
                        </>
                    )}
                </StyledModal>
            </>
        );
    }

    private async convertToPng(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                if (!ctx) {
                    reject("Canvas context is not supported.");
                    return;
                }

                canvas.width = image.width;
                canvas.height = image.height;

                ctx.drawImage(image, 0, 0);

                canvas.toBlob(
                    (blob) => {
                        if (!blob) {
                            reject("Conversion to PNG failed.");
                            return;
                        }

                        const pngFile = new File(
                            [blob],
                            file.name.replace(/\.\w+$/, ".png")
                        );

                        const pngUrl = URL.createObjectURL(pngFile);

                        resolve(pngUrl);
                    },
                    "image/png",
                    1
                );
            };

            image.onerror = () => {
                reject("Image loading failed.");
            };

            image.src = URL.createObjectURL(file);
        });
    }

    private onFileSelected = async (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.currentTarget.files;
        console.log(files);
        if (files && files[0]) {
            const file = files[0];

            const allowedExtensions = ["jpeg", "jpg", "png", "svg", "webp"];
            const fileName = file.name.toLowerCase();
            const isValidExtension = allowedExtensions.some((ext) =>
                fileName.endsWith(ext)
            );

            if (!isValidExtension) {
                this.showMessage("Unsupported format.", "invalidExtension");
                return;
            }

            if (fileName.endsWith(".svg") || fileName.endsWith(".webp")) {
                try {
                    const pngUrl = await this.convertToPng(file);

                    this.setState({
                        imageUrl: pngUrl,
                        imageSizeExceeded: false,
                        imageSizeExceededMax: false,
                    });
                } catch (error) {
                    console.error(error);
                }
            } else {
                const image = new Image();

                image.onload = () => {
                    const imageWidth = image.width;
                    const imageHeight = image.height;

                    if (imageWidth < 180 || imageHeight < 180) {
                        this.showMessage(
                            "Choose a photo that's at least 180 pixels in width or height.",
                            "sizeExceeded"
                        );
                        return;
                    }

                    const fileSizeKB = file.size / 1024;

                    if (fileSizeKB > 10000) {
                        this.showMessage(
                            "Max allowed size is 10MB.",
                            "sizeExceeded"
                        );
                        return;
                    }

                    this.setState({
                        imageUrl: URL.createObjectURL(file),
                        imageSizeExceeded: false,
                        imageSizeExceededMax: false,
                    });
                };

                image.src = URL.createObjectURL(file);
            }
        }
    };

    private showMessage(
        message: string,
        messageType: "sizeExceeded" | "invalidExtension"
    ) {
        this.setState({
            showMessage: true,
            messageText: message,
            messageType: messageType,
        });

        setTimeout(() => {
            this.setState({
                showMessage: false,
                messageText: "",
                messageType: null,
            });
        }, 3000);
    }

    private setImageUrlFromFile = (file: File) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            if (event.target && event.target.result) {
                this.setState({
                    imageUrl: event.target.result.toString(),
                });
            }
        };
        reader.readAsDataURL(file);
    };

    private onDrop = async (e: React.DragEvent<HTMLLabelElement>) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];

        const allowedExtensions = ["jpeg", "jpg", "png", "svg", "webp"];
        const fileName = file.name.toLowerCase();
        const isValidExtension = allowedExtensions.some((ext) =>
            fileName.endsWith(ext)
        );

        if (!isValidExtension) {
            this.showMessage("Unsupported format.", "invalidExtension");
            return;
        }

        if (fileName.endsWith(".svg") || fileName.endsWith(".webp")) {
            try {
                const pngUrl = await this.convertToPng(file);

                this.setState({
                    imageUrl: pngUrl,
                    imageSizeExceeded: false,
                    imageSizeExceededMax: false,
                });
            } catch (error) {
                console.error(error);
            }
        } else {
            const image = new Image();

            image.onload = () => {
                const imageWidth = image.width;
                const imageHeight = image.height;

                if (imageWidth < 180 || imageHeight < 180) {
                    this.showMessage(
                        "Choose a photo that's at least 180 pixels wide.",
                        "sizeExceeded"
                    );
                    return;
                }

                const fileSizeKB = file.size / 1024;

                if (fileSizeKB > 10000) {
                    this.showMessage(
                        "Max allowed size is 10MB.",
                        "sizeExceeded"
                    );
                    return;
                }

                this.setImageUrlFromFile(file);
            };

            image.src = URL.createObjectURL(file);
        }
    };

    private saveCroppedImage = async () => {
        if (
            !this.cropperRef ||
            !this.cropperRef.current ||
            !this.cropperRef.current.cropper
        ) {
            return;
        }
        const { onSave } = this.props;
        this.setState({ submitting: true });

        const croppedCanvas =
            this.cropperRef.current.cropper.getCroppedCanvas();

        const croppedWidth = croppedCanvas.width;
        const croppedHeight = croppedCanvas.height;

        const targetWidth = 180;
        const targetHeight = 180;

        const scaleWidth = targetWidth / croppedWidth;
        const scaleHeight = targetHeight / croppedHeight;
        const scale = Math.min(scaleWidth, scaleHeight);

        const scaledWidth = croppedWidth * scale;
        const scaledHeight = croppedHeight * scale;

        const scaledCanvas = document.createElement("canvas");
        const scaledCtx = scaledCanvas.getContext("2d");
        if (!scaledCtx) {
            await onSave(croppedCanvas.toDataURL());
            this.setState({
                submitting: false,
            });
            return;
        }

        scaledCanvas.width = scaledWidth;
        scaledCanvas.height = scaledHeight;

        scaledCtx.drawImage(
            croppedCanvas,
            0,
            0,
            croppedWidth,
            croppedHeight,
            0,
            0,
            scaledWidth,
            scaledHeight
        );

        const scaledImgData = scaledCanvas.toDataURL();

        const res = dataURItoBlob(scaledImgData);
        await onSave(res);

        this.setState({
            submitting: false,
        });
    };

    private handleCropChange = () => {
        if (
            !this.cropperRef ||
            !this.cropperRef.current ||
            !this.cropperRef.current.cropper
        ) {
            return;
        }
        const croppedImgData = this.cropperRef.current.cropper
            .getCroppedCanvas()
            .toDataURL();

        this.setState({ croppedImgSrc: croppedImgData });
    };

    private changeZoom = (e: ChangeEvent<HTMLInputElement>) => {
        if (
            !this.cropperRef ||
            !this.cropperRef.current ||
            !this.cropperRef.current.cropper
        ) {
            return;
        }

        const zoomValue = e.currentTarget.valueAsNumber;
        const zoomLevel = zoomValue / 100;

        this.setState({
            zoomSliderValue: zoomValue,
        });

        this.cropperRef.current.cropper.zoomTo(zoomLevel);
    };

    private close = () => {
        if (this.state.submitting) {
            return;
        }
        this.props.uiOnRequestClose();
    };

    private onClose = () => {
        this.setState({
            imageUrl: undefined,
            croppedImgSrc: undefined,
            image: undefined,
            rotate: 0,
        });
    };
}

function dataURItoBlob(dataURI: string) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    const blob = new Blob([ab], { type: mimeString });
    return blob;
}
